import { graphql, Link } from "gatsby"
import React from "react"
import moment from "moment";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import SEO from '../components/seo'
import Sidebar from "../components/Sidebar"
import Layout from '../components/Layout'
const baseuri = "https://dmf5.xyz"
const baseuridemo = "http://admin.hovinx.com"

class BlogIndex extends React.Component {

  render() {
    const { data } = this.props
    console.log(data)
    const siteTitle = data.site.siteMetadata.title
    const siteDescription = data.site.siteMetadata.description
    const domainName = data.site.siteMetadata.domainName
    const posts = data.privateGraphql.news
    const { currentPage, numPages } = this.props.pageContext
    let curr = 1;
    if (currentPage === undefined) { curr = 1 }
    else { curr = currentPage }
    const isFirst = curr === 1
    const isLast = curr === numPages
    const prevPage = curr - 1 === 1 ? '/' : (curr - 1).toString()
    const nextPage = (curr + 1).toString()
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={`${siteTitle} ${curr === 1 ? '' : ` - Trang ${curr}`}`}
          description={`${siteDescription} ${curr === 1 ? '' : ` - Trang ${curr}`}`}
          canonical={`https://${domainName}`}
        />
        <div className="row">
          <div className="col-lg-8 col-md-12">
              <div className="row">
          {posts.map(node => {
            const title = node.title || node.slugtitle
            return (
              <div className="col-lg-6 col-md-6" key={node.slugtitle}>
                <article className="elementor-post">
                  <div className="elementor-post__card">
                    <Link to={node.slugtitle} title={title} className="elementor-post__thumbnail__link">
                      <div className="elementor-post__thumbnail">
                        <LazyLoadImage
                          className="size-medium_large"
                          alt={title}
                          title={title}
                          src={node.image}
                          effect="blur"
                        />
                      </div>
                    </Link>

                    <div className="elementor-post__text">
                      <div className="elementor-post__excerpt">
                        <div className="category">
                          {node.categoryName !== null ? <Link to={`/${node.slugCategoryName}`}>#{node.categoryName}</Link> : "#Tin Tức Bóng Đá"}
                        </div>
                        <h2 className="post-title">
                          <Link to={node.slugtitle} title={title}>{title}</Link>
                        </h2>
                        <div className="excerpt" dangerouslySetInnerHTML={{ __html: node.short }} />
                        <div className="post-sub-section">
                          <LazyLoadImage
                            className="avatar avatar-80 photo"
                            title={node.profileName}
                            src={node.profileImage}
                            effect="blur"
                          />
                          <div className="author">
                            <div className="author-name">{node.profileName}</div>
                            <div className="reading-time">
                              {moment(node.modifiedDate).format('DD.MM.YYYY')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            )
          })}
          </div>
          </div>
          <Sidebar></Sidebar>
        </div>
        <div className="row pagination">
          <ul className="col d-flex justify-content-center">
            {!isFirst && (
              <li className="tb-pagination__link">
                <Link to={curr <= 2 ? `/` : `/page/${prevPage}`} rel="prev">
                  ←
                </Link>
              </li>
            )}
            {Array.from({ length: numPages }, (_, i) => {
              if (i < curr + 3 && i > curr - 3) {
                return (
                  <li
                    key={`pagination-number${i + 1}`}
                    className="tb-pagination__link"
                  >
                    <Link
                      to={`/${i === 0 ? '' : 'page/' + (i + 1)}`}
                      className={i + 1 === curr ? "current" : "page-s"}
                    >
                      {i + 1}
                    </Link>
                  </li>
                )
              }
            })}
            {!isLast && (
              <li className="tb-pagination__link">
                <Link to={`/page/${nextPage}`} rel="next">
                  →
                </Link>
              </li>
            )}
          </ul>
        </div>
      </Layout>
    )
  }
}
export default BlogIndex
export const query = graphql`
query ($limit: Int = 21, $skip: Int = 0, $siteId: Int = 1082) {
  site {
    siteMetadata {
      title
      domainName
      description
    }
  }
  privateGraphql {
    news(
      limit: $limit 
      skip: $skip,
      siteId: $siteId
      ) {
        id
        categoryName
        image
        profileName
        profileSlugName
        publishDate
        short
        slugCategoryName
        tags
        slugtags
        slugtitle
        title
        profileImage
        modifiedDate
    }
  }
}
`
